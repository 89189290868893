import { createGlobalStyle } from 'styled-components'
import 'primereact/resources/themes/lara-light-indigo/theme.css'
import 'primereact/resources/primereact.min.css'
import 'primeicons/primeicons.css'

import { theme } from './theme'
import { fonts } from './fonts'

const GlobalStyles = createGlobalStyle`

  body {
    margin: 0;
    font-family: Nunito-Regular !important;
  }

  .backgraund {
    height: 50vh;
    padding-top: 20px;
    background: ${theme.primaryColor};
    border-bottom-right-radius: 500px 50px;
    border-bottom-left-radius: 500px 50px;
    position: absolute;
    top: 0;
    right: 0;
    z-index: -1;
    width: 100%;
  }

  .backgraund-body {
    padding-top: 20px;
  }

  .container-body {
    width: 100%;
    max-height: 100vh;
  }

  .largeBoby {
    width: calc(100% - 101px);
  }

  .smallBody {
    width: calc(100% - 298px);
  }

  .container-header-menu {
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 20px 0;
    height: 90px
  }

  .container-skeleton-btn {
    display: flex;
    gap: 5px;
  }

  .container-date, .container-name {
    display: flex;
    gap: 10px;
  }

  .container-date div, .container-name div {
    width: 100%;
  }

  .tab-menu {
    background: ${theme.white};
    box-shadow: 0px 0px 30px rgb(0 0 0 / 11%);
    transition: width 0.2s ease 0s;
  }

  .hidden-text-menu span {
    display: none;
  }

  .p-component {
    font-family: Nunito-Regular;
  }

  .p-tabmenu-nav {
    display: block;
    height: calc(100vh - 105px);
  }

  .p-tabmenuitem {
    margin: 5px 20px !important;
  }

  .p-menuitem-link {
    border-radius: 6px;
    font-family: Nunito-Regular;
    border-color: ${theme.white} !important;
    margin: 0;
    gap: 10px;
  }

  .p-menuitem-icon {
    margin-right: 0 !important;
    width: 24px;
    height: 16px;
  }

  .p-tabmenu .p-tabmenu-nav .p-tabmenuitem.p-highlight .p-menuitem-link {
    color: ${theme.white} !important;
    fill: ${theme.white} !important;
    background-color: ${theme.primaryColor};
    transition: all 0.5s ease 0s;
  }

  .p-menuitem-link:hover {
    color: ${theme.primaryColor} !important;
    fill: ${theme.primaryColor} !important;
  }

  .p-button.p-button-warning {
    margin-left: 5px;
    background-color: ${theme.red};
    border-color: ${theme.red};
  }

  .bar-header {
    display: flex;
    gap: 10px;
    justify-content: end;
    align-items: center;
    padding: 0 20px;
    background: ${theme.white};
    border-radius: 6px;
    margin: 0 20px;
    height: 50px;
    box-shadow: 0px 0px 30px rgb(0 0 0 / 11%);
  }

  .p-tabmenu .p-tabmenu-nav .p-tabmenuitem .p-menuitem-link {
    fill: ${theme.grey};
  }

  .p-tabmenu .p-tabmenu-nav .p-tabmenuitem .p-menuitem-link:not(.p-disabled):focus {
    box-shadow: inset 0 0 0 0.2rem ${theme.lightBlue};
  }

  .p-toolbar-group-left h3 {
    margin: 0;
  }

  .landing-header {
    height: 150px;
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .container-card {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100vh;
    background: ${theme.primaryColor};
  }

  .card {
    background-color: ${theme.white};
    padding: 20px;
    box-shadow: 0px 0px 30px rgb(0 0 0 / 11%);
    border-radius: 6px;
    height: calc(100vh - 110px);
  }

  .card-login {
    width: 100%;
    background-color: ${theme.white};
    padding: 40px;
    box-shadow: 0px 0px 30px rgb(0 0 0 / 11%);
    max-width: 450px;
    border-radius: 15px;
  }

  .text-landing {
    margin: 40px 0;
    font-size: 16px;
    text-align: center;
    color: ${theme.primaryColor};
  }

  .text-publish-required {
    font-size: 14px;
    color: ${theme.lightGrey};
  }

  .login-form {
    display: grid;
    gap: 30px;
  }

  .dialog-form {
    display: grid;
    gap: 25px;
  }

  .dialog-footer {
    display: flex;
    gap: 10px;
    justify-content: end;
    width: 100%;
  }

  .dialog-footer button {
    width: 170px !important;
  }

  .p-inputtext {
    width: 100%;
  }

  .p-button-success {
    background-color: ${theme.green} !important;
    font-family: Nunito-Regular;
    border-color: ${theme.green} !important;
  }

  .p-button, .p-togglebutton.p-button.p-highlight{
    background-color: ${theme.primaryColor};
    border-color: ${theme.primaryColor};
  }

  .p-button:hover, .p-button:enabled:hover, .p-button:not(button):not(a):not(.p-disabled):hover {
    background-color: ${theme.blue};
  }

  .p-dialog .p-dialog-content {
    padding: 2rem 1.5rem;
  }

  .p-datatable .p-datatable-header {
    padding: 0 1rem;
  }

  .p-datatable-flex-scrollable {
    height: calc(100vh - 260px);
  }

  .p-datatable .p-datatable-tbody > tr > td {
    padding: 5px 1rem;
  }

  .p-dialog .p-dialog-content {
    padding-top: 20px;
  }

  .table-header {
    display: flex;
    gap: 10px;
  }

  .field-checkbox {
    display: flex;
    gap: 10px;
  }

  .status-badge {
    width: 102px;
    text-align: center;
    border-radius: 3px;
    padding: 0.25em 0.5rem;
    text-transform: uppercase;
    font-weight: 700;
    font-size: 12px;
    letter-spacing: .3px;
  }

  .status-badge.status-false {
    background-color: #ffd8b2;
    color: #805b36;
  }

  .status-badge.status-true {
    background-color: #c8e6c9;
    color: #256029;
  }

  .p-fluid .p-button-icon-only {
    width: 48px !important;
  }

  .p-button.p-button-text {
    color: ${theme.primaryColor} !important;
  }

  .p-float-label input:-webkit-autofill ~ label {
    top: -0.75rem;
  }

  .container-info-award {
    display: grid;
    gap: 10px;
    width: 100%;
    font-size: 18px;
  }
  
  .info-award {
    display: flex;
    text-align: center;
    gap: 5px;
    margin-bottom: 30px;
  }

  .text-award {
    background-color: ${theme.blue};
    border-radius: 5px;
    padding: 5px 4px;
    width: 100%;
    font-size: 15px;
  }

  .info-award-header {
    text-align: center;
    background-color: ${theme.primaryColor};
    border-bottom-right-radius: 200px 50px;
    border-bottom-left-radius: 200px 50px;
    padding: 20px;
    padding-top: 0;
    height: 250px;
    color: ${theme.white};
  }

  .container-img-award {
    width: 360px;
    display: flex;
    justify-content: center;
  }

  .img-award {
    border-radius: 10px;
    background-color: ${theme.white};
    border: 4px solid ${theme.primaryColor};
  }

  .info-award-user {
    padding: 90px 20px 40px;
    display: grid;
  }

  .info-award-user span {
    width: 360px;
    word-wrap: break-word;
  }

  .info-award-header h3, .info-award-user h3 {
    margin-top: 0;
  }

  .container-file-img {
    display: flex;
    gap: 20px;
  }

  .p-fileupload {
    width: 100%;
  }

  .p-image-preview-container img {
    min-width: 160px;
    min-height: 160px;
    width: 160px;
    height: 160px;
    object-fit: scale-down;
    margin: 20px;
  }

  .p-image-preview-container.img-awards img {
    min-width: 70px;
    min-height: 70px;
    max-width: 70px;
    max-height: 70px;
  }

  .p-image-preview-indicator {
    border-radius: 6px;
  }

  .overflow-none .p-dialog-content{
    overflow: hidden;
  }

  .container-info-user-awards {
    display: flex;
    justify-content: space-between;
  }

  .container-info-notification {
    display: flex;
    align-items: center;
    gap: 15px;
  }

  .container-info-notification h3{
    margin: 0;
  }

  .title-notification {
    margin: 0;
    margin-bottom: 10px;
  }

  .info-user-edit-awards {
    display: flex;
    gap: 100px;
  }

  .info-user-awards {
    display: grid;
    gap: 5px;
  }

  .p-button-danger span {
    color: ${theme.red} !important;
  }

  .p-button-succes span {
    color: ${theme.green} !important;
  }

  .p-dropdown:not(.p-disabled).p-focus {
    box-shadow: none;
    border-color: #ced4da;
  }

  .p-dropdown:active {
    box-shadow: 0 0 0 0.2rem #c7d2fe;
    border-color: ${theme.blue};
  }

  .p-button.p-button-icon-only:focus {
    box-shadow: none;
  }

  .p-button:focus {
    box-shadow: none;
  }

  .p-button:active {
    box-shadow: 0 0 0 2px #ffffff, 0 0 0 4px #b1b3f8, 0 1px 2px 0 black;
  }

  @media (max-width: 510px) {
    .container-card{
      background: ${theme.white};
    }

    .p-datatable-flex-scrollable {
      height: calc(100vh - 100px);
    }
  }

  @media (max-width: 1024px) {
    .tab-menu {
      position: absolute;
      z-index: 10;
    }

    .hiddenTabMenu {
      background: transparent;
      box-shadow: none;
    }

    .hidden-text-menu {
      display: none;
    }

    .smallBody, .largeBoby{
      width: 100%;
    }
  }

  .p-date-picker-prev, .p-date-picker-next {
    :focus {
      box-shadow: 0 !important;
    }
  }

  .p-button-without-icon {
    .p-button-icon {
      display: none;
    }
  }

  ${fonts}
`
export { GlobalStyles }
