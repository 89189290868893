/* eslint-disable @typescript-eslint/no-explicit-any */
import { useState, useRef } from 'react'
import { ConfirmDialog, confirmDialog } from 'primereact/confirmdialog'
import { Toolbar } from 'primereact/toolbar'
import { DataTable } from 'primereact/datatable'
import { Button } from 'primereact/button'
import { Column } from 'primereact/column'
import { Messages } from 'primereact/messages'
import { useTranslation } from 'react-i18next'
import { Toast } from 'primereact/toast'
import styled from 'styled-components'

import { AwardsTransferValue, ErrorResponse, TransferListTypeHandel } from '@types'
import { useApiErrorService } from '@hooks'
import { TransferAward } from '@components'

import { useTransferList } from './hooks/useTransferList'

const ImgAward = styled.img`
  width: 60px;
  height: 60px;
  object-fit: scale-down;
`

const TransferList = () => {
  const { t } = useTranslation()
  const message = useRef<any>(null)
  const toast = useRef<any>(null)
  const [transferDialog, setTransferDialog] = useState(false)
  const [rowsPerPage, setRowsPerPage] = useState(10)
  const [awardValue, setAwardValue] = useState<AwardsTransferValue>()
  const [page, setPage] = useState(0)

  const { mapErrorToMessage } = useApiErrorService()

  const { transferList, refetch } = useTransferList({ page: page + 1, limit: rowsPerPage })

  const actionBodyTemplate = (rowData: TransferListTypeHandel) => {
    return (
      <>
        <Button
          icon="pi pi-pencil"
          className="p-button-rounded p-button-text p-button-plain"
          onClick={() => handelTransfer(rowData)}
        />
      </>
    )
  }

  const handelTransfer = (awards: TransferListTypeHandel) => {
    const { owner, mnft, id, walletInfo, createdAt } = awards
    setAwardValue({
      id: mnft.id,
      owner,
      mNftToSend: {
        id,
        walletInfo,
        createdAt,
      },
      image: mnft.image,
    })
    setTransferDialog(true)
  }

  const imageBodyTemplate = (rowData: TransferListTypeHandel) => {
    return <ImgAward loading="lazy" src={rowData.mnft.image} alt={`awards-${rowData.mnft.token}`} />
  }

  const messageError = (event: ErrorResponse) => {
    message.current.show({
      severity: 'error',
      detail: mapErrorToMessage(event),
      life: 10000,
    })
  }

  const handlePageChange = e => {
    setRowsPerPage(e.rows)
    setPage(e.page)
  }

  const dateBodyTransfer = (data: TransferListTypeHandel) => {
    const date = new Date(data.createdAt)
    return `${date.toLocaleDateString()} ${date.toLocaleTimeString()}`
  }

  const userWalletTemplate = (rowData: TransferListTypeHandel) => {
    return rowData.walletInfo ? `${rowData.walletInfo.substr(0, 10)}...` : '-'
  }

  const tokenIdTemplate = (rowData: TransferListTypeHandel) => {
    return rowData.mnft.token ? `${rowData.mnft.token.substr(0, 10)}...` : '-'
  }

  return (
    <div className="card">
      <ConfirmDialog />
      <Toast ref={toast} />
      <Toolbar left={<h3>{t('transferList.title')}</h3>} style={{ marginBottom: '15px' }} />
      <DataTable
        value={transferList?.data}
        className="p-datatable-striped"
        scrollable
        scrollHeight="flex"
        responsiveLayout="scroll"
        lazy
        loading={!transferList?.data}
        paginator
        first={page * rowsPerPage}
        rows={rowsPerPage}
        totalRecords={transferList?.total}
        rowsPerPageOptions={[5, 10, 25]}
        paginatorTemplate="FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink CurrentPageReport RowsPerPageDropdown"
        currentPageReportTemplate={t('transferList.current_page_report')}
        onPage={e => handlePageChange(e)}
      >
        <Column field="id" header="#" style={{ minWidth: '3rem' }} />
        <Column
          field="owner.username"
          header={t('transferList.username')}
          style={{ minWidth: '19rem' }}
        />
        <Column field="owner.name" header={t('transferList.name')} style={{ minWidth: '10rem' }} />
        <Column
          field="owner.lastname"
          header={t('transferList.lastname')}
          style={{ minWidth: '10rem' }}
        />
        <Column
          header={t('transferList.wallet')}
          style={{ minWidth: '9rem' }}
          body={userWalletTemplate}
        />
        <Column
          field="image"
          header={t('transferList.image')}
          style={{ minWidth: '6rem' }}
          body={imageBodyTemplate}
        />
        <Column
          field="mnft.token"
          header="NFT"
          style={{ minWidth: '9rem' }}
          body={tokenIdTemplate}
        />
        <Column
          header={t('transferList.date_application')}
          style={{ minWidth: '12rem' }}
          body={dateBodyTransfer}
        />
        <Column
          header={t('globals.actions')}
          body={actionBodyTemplate}
          style={{ minWidth: '6rem' }}
        />
      </DataTable>
      <TransferAward
        t={t}
        messageError={messageError}
        confirmDialog={confirmDialog}
        value={awardValue!}
        refetchProp={refetch}
        toast={toast}
        setAwardsDialogProp={setTransferDialog}
        visibleDialog={transferDialog}
      >
        <Messages ref={message} />
      </TransferAward>
    </div>
  )
}

export { TransferList }
