/* eslint-disable @typescript-eslint/no-explicit-any */
import { useCallback, useEffect, useRef, useState } from 'react'
import { Dialog } from 'primereact/dialog'
import { useForm } from 'react-hook-form'
import { DataTable } from 'primereact/datatable'
import { Column } from 'primereact/column'
import { Toolbar } from 'primereact/toolbar'
import { Button } from 'primereact/button'
import { FileUpload } from 'primereact/fileupload'
import { Messages } from 'primereact/messages'
import { Toast } from 'primereact/toast'
import { ConfirmDialog, confirmDialog } from 'primereact/confirmdialog'
import { Image } from 'primereact/image'
import { useTranslation } from 'react-i18next'
import styled from 'styled-components'

import { DropDownForm, InputForm } from '@components'
import { ErrorResponse, MinigolsType, Positions } from '@types'
import { StringUtils } from '@utils'
import { useApiErrorService, useTeams } from '@hooks'

import { useMinigols } from './hooks/useMinigols'
import { useNewMinigols } from './hooks/useNewMinigols'
import { useDeleteMinigols } from './hooks/useDeleteMinigols'
import { useUpdateMinigols } from './hooks/useUpdateMinigols'
import { usePositions } from './hooks/usePositions'

const ImgMinigols = styled.img`
  width: 60px;
  height: 60px;
  object-fit: scale-down;
`

interface Minigol {
  id: number
  name: string
  lastName: string
  nickname: string
  shirtNumber: number
  fieldPosition: { name: string; id: string }
  teamId: { id: number; name: string }
  team?: {
    createdAt: string
    id: number
    image: string
    imageKey: string
    name: string
    publish: boolean
  }
}

interface TeamValues {
  name: string
  id: number
}

interface ImageMinigolsOld {
  image: string
  imageBaw: string
}

const Minigols = () => {
  const { t } = useTranslation()
  const fileUploadRef = useRef<any>(null)
  const message = useRef<any>(null)
  const toast = useRef<any>(null)
  const [MinigolsDialog, setMinigolsDialog] = useState(false)
  const [imgMinigols, setImgMinigols] = useState<File | undefined>()
  const [imgMinigolsBaw, setImgMinigolsBaw] = useState<File | undefined>()
  const [imgMinigolsOld, setImgMinigolsOld] = useState<ImageMinigolsOld | undefined>()
  const [dropDownTeams, setDropDownTeams] = useState<TeamValues[]>([])
  const [isEdit, setIsEdit] = useState(false)
  const [rowsPerPage, setRowsPerPage] = useState(10)
  const [overflowDialog, setOverflowDialog] = useState(true)
  const [page, setPage] = useState(0)
  const defaultValues: {
    id: number
    name: string
    lastName: string
    shirtNumber: string | number
    nickname: string
    fieldPosition: { name: string; id: Positions } | object
    teamId: { name: string; id: number } | object
  } = {
    id: 0,
    name: '',
    lastName: '',
    shirtNumber: '',
    nickname: '',
    fieldPosition: {},
    teamId: {},
  }

  const { mapErrorToMessage } = useApiErrorService()
  const { minigols, refetch } = useMinigols({ page: page + 1, limit: rowsPerPage })
  const { position } = usePositions()
  const { teams } = useTeams({ page: 1, limit: 50 })

  const {
    control,
    setValue,
    getValues,
    formState: { errors },
    handleSubmit,
    reset,
  } = useForm({ defaultValues })

  const openNew = () => {
    clearForm()
    setIsEdit(false)
    setMinigolsDialog(true)
  }

  useEffect(() => {
    const valuesTeams: TeamValues[] = []
    if (teams) {
      teams.map(team => valuesTeams.push({ name: team.name, id: team.id }))
      setDropDownTeams(valuesTeams)
    }
  }, [teams])

  const editMinigols = (minigols: MinigolsType) => {
    setValue('id', minigols.id)
    setValue('name', minigols.name)
    setValue('lastName', minigols.lastname)
    setValue('shirtNumber', minigols.shirtNumber)
    setValue('nickname', minigols.nickname)
    const namePosition = position.find(element => element.id === minigols.fieldPosition)
    setValue('fieldPosition', namePosition ? namePosition : {})
    if (minigols.team) setValue('teamId', { name: minigols.team.name, id: minigols.team.id })
    setImgMinigolsOld({ image: minigols.image, imageBaw: minigols.imageBaw })
    setIsEdit(true)
    setMinigolsDialog(true)
  }

  const actionBodyTemplate = (rowData: MinigolsType) => {
    return (
      <>
        <Button
          icon="pi pi-pencil"
          className="p-button-rounded p-button-text p-button-plain"
          onClick={() => editMinigols(rowData)}
          disabled={rowData.someUserHasInCollection}
        />
        <Button
          icon="pi pi-trash"
          className="p-button-rounded p-button-text p-button-plain"
          onClick={() => deleteSelectedMinigols(rowData)}
          disabled={rowData.someUserHasInCollection}
        />
      </>
    )
  }

  const headerControls = (
    <div className="table-header">
      <Button label={t('globals.new')} className="p-button-success" onClick={openNew} />
    </div>
  )

  const deleteSelectedMinigols = (rowData: MinigolsType) => {
    confirmDialog({
      acceptLabel: t('globals.yes'),
      message: t('minigols.confirm_delete_minigol'),
      header: t('globals.confirmation_title'),
      icon: 'pi pi-exclamation-triangle',
      accept: () => deleteMinigols({ minigolsId: rowData.id }),
    })
  }

  const imageBodyTemplate = (rowData: MinigolsType) => {
    return <ImgMinigols loading="lazy" src={rowData.image} alt={`minigols-${rowData.name}`} />
  }

  const onSubmit = data => {
    message.current.clear()
    confirmDialog({
      acceptLabel: t('globals.yes'),
      message: isEdit ? t('minigols.confirm_update_minigol') : t('minigols.confirm_create_minigol'),
      header: t('globals.confirmation_title'),
      icon: 'pi pi-exclamation-triangle',
      accept: () => confirmSubmit(data),
    })
  }

  const confirmSubmit = (data: Minigol) => {
    if ((imgMinigols && imgMinigolsBaw) || (imgMinigolsOld?.image && imgMinigolsOld?.imageBaw)) {
      let minigolsData = {}
      const {
        id,
        teamId: { id: teamId },
        fieldPosition: { id: fieldPosition },
        lastName: lastname,
        name,
        ...other
      } = data
      minigolsData = {
        ...other,
        teamId,
        fieldPosition,
        name: StringUtils.initialCapitalLetters(name),
        lastname: StringUtils.initialCapitalLetters(lastname),
      }
      if (fieldPosition && teamId) {
        return isEdit
          ? updateMinigol({
              minigolsId: id,
              minigols: minigolsData,
              picture: imgMinigols as File,
              pictureBaw: imgMinigolsBaw as File,
            })
          : createNewMinigol({
              minigols: {
                ...minigolsData,
              },
              picture: imgMinigols as File,
              pictureBaw: imgMinigolsBaw as File,
            })
      }
      return message.current.show({
        severity: 'error',
        detail: t('minigols.api.error_empty_field'),
        life: 10000,
      })
    }
    return message.current.show({
      severity: 'error',
      detail: t('minigols.error_empty_image'),
      life: 10000,
    })
  }

  const messageError = (error: ErrorResponse) => {
    message.current.show({
      severity: 'error',
      detail: mapErrorToMessage(error),
      life: 10000,
    })
  }

  const { createNewMinigol, isCreateLoading, isCreateSuccess } = useNewMinigols({
    onError: (error: ErrorResponse) => {
      messageError(error)
    },
  })

  const { updateMinigol, isUpdateLoading, isUpdateSuccess } = useUpdateMinigols({
    onError: (error: ErrorResponse) => {
      messageError(error)
    },
  })

  const { deleteMinigols, isDeleteSuccess } = useDeleteMinigols({
    onError: (error: ErrorResponse) => {
      toast.current.show({
        severity: 'error',
        detail: mapErrorToMessage(error),
        life: 5000,
      })
    },
  })

  const clearForm = useCallback(() => {
    reset()
    setImgMinigols(undefined)
    setImgMinigolsBaw(undefined)
    setImgMinigolsOld(undefined)
    setMinigolsDialog(false)
    if (fileUploadRef.current) fileUploadRef.current.clear()
  }, [reset, fileUploadRef])

  // refactor this
  useEffect(() => {
    if (isCreateSuccess) {
      clearForm()
      toast.current.show({
        severity: 'success',
        summary: t('globals.created'),
        detail: t('minigols.created'),
        life: 5000,
      })
      refetch()
    }
  }, [isCreateSuccess, refetch, t, clearForm])

  useEffect(() => {
    if (isUpdateSuccess) {
      clearForm()
      toast.current.show({
        severity: 'success',
        summary: t('globals.updated'),
        detail: t('minigols.updated'),
        life: 5000,
      })
      refetch()
    }
  }, [isUpdateSuccess, refetch, t, clearForm])

  useEffect(() => {
    if (isDeleteSuccess) {
      clearForm()
      toast.current.show({
        severity: 'success',
        summary: t('globals.deleted'),
        detail: t('minigols.deleted'),
        life: 5000,
      })
      refetch()
    }
  }, [isDeleteSuccess, refetch, t, clearForm])

  const handlePageChange = e => {
    setRowsPerPage(e.rows)
    setPage(e.page)
  }

  const fileldPositionTemplate = (rowData: MinigolsType) => {
    const namePosition = position.find(element => element.id === rowData.fieldPosition)
    return namePosition ? namePosition.name : ''
  }

  const closeDialog = () => {
    const valuesMinigols = getValues()
    if (
      valuesMinigols.name ||
      valuesMinigols.lastName ||
      valuesMinigols.shirtNumber ||
      valuesMinigols.nickname
    ) {
      confirmDialog({
        acceptLabel: t('globals.yes'),
        message: t('globals.confirm_close_form'),
        header: t('globals.confirmation_title'),
        icon: 'pi pi-exclamation-triangle',
        accept: () => clearForm(),
      })
    } else {
      clearForm()
    }
  }

  const teamsTemplate = (rowData: MinigolsType) => {
    return rowData.team ? rowData.team.name : '-'
  }

  return (
    <div className="card">
      <ConfirmDialog />
      <Toast ref={toast} />
      <Toolbar left={<h3>Minigols</h3>} right={headerControls} style={{ marginBottom: '15px' }} />
      <DataTable
        value={minigols?.data}
        className="p-datatable-striped"
        scrollable
        scrollHeight="flex"
        responsiveLayout="scroll"
        lazy
        loading={!minigols?.data}
        paginator
        first={page * rowsPerPage}
        rows={rowsPerPage}
        totalRecords={minigols?.total}
        rowsPerPageOptions={[5, 10, 25]}
        paginatorTemplate="FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink CurrentPageReport RowsPerPageDropdown"
        currentPageReportTemplate={t('minigols.current_page_report')}
        onPage={e => handlePageChange(e)}
      >
        <Column field="id" header="#" style={{ minWidth: '3rem' }} />
        <Column field="name" header={t('minigols.name')} style={{ minWidth: '8rem' }} />
        <Column field="lastname" header={t('minigols.lastname')} style={{ minWidth: '8rem' }} />
        <Column field="nickname" header={t('minigols.nickname')} style={{ minWidth: '8rem' }} />
        <Column
          field="team.name"
          body={teamsTemplate}
          header={t('minigols.teams')}
          style={{ minWidth: '8rem' }}
        />
        <Column
          field="shirtNumber"
          header={t('minigols.shirt_number')}
          style={{ minWidth: '6rem' }}
        />
        <Column
          field="fieldPosition"
          header={t('minigols.position')}
          style={{ minWidth: '9rem' }}
          body={fileldPositionTemplate}
        />
        <Column
          field="image"
          header={t('minigols.image')}
          style={{ minWidth: '7rem' }}
          body={imageBodyTemplate}
        />
        <Column
          header={t('globals.actions')}
          body={actionBodyTemplate}
          style={{ minWidth: '8rem' }}
        />
      </DataTable>
      <Dialog
        visible={MinigolsDialog}
        style={{ width: '700px' }}
        header={isEdit ? t('minigols.edit_minigol') : t('minigols.new_minigol')}
        modal
        draggable={false}
        className={`p-fluid ${!overflowDialog ? 'overflow-none' : ''}`}
        onHide={() => closeDialog()}
      >
        <form className="dialog-form" onSubmit={handleSubmit(onSubmit)}>
          <div className="container-name">
            <InputForm
              control={control}
              error={errors.name}
              name="name"
              rules={{
                required: t('minigols.name_required'),
                pattern: {
                  value:
                    /^[a-zA-ZÀ-ÿ'\u00f1\u00d1]+(\s*[a-zA-ZÀ-ÿ'\u00f1\u00d1]*)*[a-zA-ZÀ-ÿ'\u00f1\u00d1.-]+$/,
                  message: t('minigols.name_invalid'),
                },
              }}
              max={30}
              placeholder={t('minigols.name')}
            />
            <InputForm
              control={control}
              error={errors.lastName}
              name="lastName"
              rules={{
                required: t('minigols.lastname_required'),
                pattern: {
                  value:
                    /^[a-zA-ZÀ-ÿ'\u00f1\u00d1]+(\s*[a-zA-ZÀ-ÿ'\u00f1\u00d1]*)*[a-zA-ZÀ-ÿ'\u00f1\u00d1.-]+$/,
                  message: t('minigols.lastname_invalid'),
                },
              }}
              max={30}
              autoFocus={false}
              placeholder={t('minigols.lastname')}
            />
          </div>
          <InputForm
            control={control}
            error={errors.nickname}
            name="nickname"
            rules={{
              required: t('minigols.nickname_required'),
              pattern: {
                value:
                  /^[a-zA-ZÀ-ÿ'\u00f1\u00d1]+(\s*[a-zA-ZÀ-ÿ'\u00f1\u00d1]*)*[a-zA-ZÀ-ÿ'\u00f1\u00d1.-]+$/,
                message: t('minigols.nickname_invalid'),
              },
            }}
            max={30}
            autoFocus={false}
            placeholder={t('minigols.nickname')}
          />
          <InputForm
            control={control}
            error={errors.shirtNumber}
            name="shirtNumber"
            rules={{
              required: t('minigols.shirt_number_required'),
              pattern: {
                value: /^[0-9]+$/,
                message: t('minigols.shirt_number_invalid'),
              },
            }}
            max={2}
            autoFocus={false}
            placeholder={t('minigols.shirt_number')}
          />
          <DropDownForm
            control={control}
            name="teamId"
            options={dropDownTeams}
            autoFocus={false}
            placeholder={t('minigols.teams')}
            onFocus={() => setOverflowDialog(overflowDialog => !overflowDialog)}
          />
          <DropDownForm
            control={control}
            name="fieldPosition"
            options={position}
            autoFocus={false}
            placeholder={t('minigols.position')}
            onFocus={() => setOverflowDialog(overflowDialog => !overflowDialog)}
          />
          <div className="container-file-img">
            <FileUpload
              ref={fileUploadRef}
              name="image"
              accept="image/png, image/jpeg"
              auto
              emptyTemplate={<p>{t('minigols.image_drop_full_color')}</p>}
              customUpload
              chooseOptions={{
                label: t('globals.image_choose'),
                className: 'p-button-without-icon',
              }}
              onRemove={() => setImgMinigols(undefined)}
              uploadHandler={event => setImgMinigols(event.files[0])}
            />
            {isEdit && imgMinigolsOld && (
              <Image src={imgMinigolsOld.image} alt={getValues('name')} preview />
            )}
          </div>
          <div className="container-file-img">
            <FileUpload
              ref={fileUploadRef}
              name="image"
              accept="image/png, image/jpeg"
              auto
              emptyTemplate={<p>{t('minigols.image_drop')}</p>}
              customUpload
              chooseOptions={{
                label: t('globals.image_choose'),
                className: 'p-button-without-icon',
              }}
              onRemove={() => setImgMinigolsBaw(undefined)}
              uploadHandler={event => setImgMinigolsBaw(event.files[0])}
            />
            {isEdit && imgMinigolsOld && (
              <Image src={imgMinigolsOld.imageBaw} alt={getValues('name')} preview />
            )}
          </div>
          <Messages ref={message} />
          <div className="dialog-footer">
            <Button
              label={t('globals.cancel')}
              icon="pi pi-times"
              type="button"
              onClick={() => closeDialog()}
              className="p-button-text"
            />
            <Button
              label={t('globals.save')}
              loading={isCreateLoading || isUpdateLoading}
              icon="pi pi-check"
              className="p-button-text"
            />
          </div>
        </form>
      </Dialog>
    </div>
  )
}

export { Minigols }
