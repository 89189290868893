/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable jsx-a11y/label-has-associated-control */
import { useRef, useState, useEffect, useMemo, useCallback } from 'react'
import { Dialog } from 'primereact/dialog'
import { useForm, Controller } from 'react-hook-form'
import { DataTable } from 'primereact/datatable'
import { Column } from 'primereact/column'
import { Toolbar } from 'primereact/toolbar'
import { Button } from 'primereact/button'
import { ConfirmDialog, confirmDialog } from 'primereact/confirmdialog'
import { InputTextarea } from 'primereact/inputtextarea'
import { Calendar } from 'primereact/calendar'
import { Messages } from 'primereact/messages'
import { Toast } from 'primereact/toast'
import { useTranslation } from 'react-i18next'
import moment from 'moment'

import { DropDownForm, InputForm } from '@components'
import { useApiErrorService } from '@hooks'

import { useNewNotifications } from './hooks/useNewNotifications'
import { useNotifications } from './hooks/useNotifications'
import { useDeleteNotifications } from './hooks/useDeleteNotifications'
import { useUpdateNotifications } from './hooks/useUpdateNotifications'

interface Notification {
  date: string
  id: string
  message: string
  time: string
  title: string
  language: string
}

const Notifications = () => {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const message = useRef<any>(null)
  const [notificationDialog, setNotificationDialog] = useState(false)
  const [infoNotificationDialog, setInfoNotificationDialog] = useState(false)
  const [selectedNotification, setSelectedNotification] = useState<Notification>()
  const filtro = {
    page: 1,
    limit: 50,
  }
  const toast = useRef<any>(null)

  const { refetch, notifications } = useNotifications(filtro)

  const newUpdateErrorCallback = {
    onError: error => {
      message.current.clear()
      message.current.show({
        severity: 'error',
        detail: mapErrorToMessage(error),
        life: 10000,
      })
    },
  }

  const { deleteNotification: deleteNotif, isDeleteSuccess } =
    useDeleteNotifications(newUpdateErrorCallback)
  const [isEdit, setIsEdit] = useState(false)
  const { createNewNotification, isCreateSuccess } = useNewNotifications(newUpdateErrorCallback)
  const { updateNotification, isUpdateSuccess } = useUpdateNotifications(newUpdateErrorCallback)

  const { t } = useTranslation()
  const { mapErrorToMessage } = useApiErrorService()
  const defaultValues = useMemo(() => {
    return {
      id: undefined as unknown as number,
      title: '',
      date: new Date(),
      message: '',
      time: new Date(),
      language: 'es',
    }
  }, [])

  const {
    control,
    formState: { errors },
    handleSubmit,
    reset,
    getValues,
    setValue,
  } = useForm({ defaultValues })

  const openNew = () => {
    setIsEdit(false)
    const nextTime = moment().add(1, 'minutes').toDate()
    reset({ ...defaultValues, time: nextTime })
    setNotificationDialog(true)
  }

  const deleteNotification = (notificationId: string) => {
    deleteNotif({ notificationId: +notificationId })
  }

  const viewSelectedNotification = (notification: Notification) => {
    setSelectedNotification(notification)
    setInfoNotificationDialog(true)
  }

  const formattedNotifications = useMemo(() => {
    return notifications.map(notif => {
      const notifDate = moment(notif.date)
      return {
        ...notif,
        date: notifDate.format('YYYY-MM-DD'),
        time: notifDate.format('HH:mm'),
      }
    })
  }, [notifications])

  const editNotification = (notification: Notification) => {
    setValue('id', +notification.id)
    setValue('title', notification.title)
    setValue('message', notification.message)
    setValue('date', moment(notification.date, 'YYYY-MM-DD').toDate())
    setValue('time', moment(notification.time, 'HH:mm').toDate())
    setIsEdit(true)
    setNotificationDialog(true)
  }

  const actionBodyTemplate = (rowData: Notification) => {
    const scheduleDate = moment(`${rowData.date} ${rowData.time}`, 'YYYY-MM-DD HH:mm')
    return (
      <>
        <Button
          icon="pi pi-pencil"
          className="p-button-rounded p-button-text p-button-plain"
          onClick={() => editNotification(rowData)}
          disabled={scheduleDate.isBefore(Date.now())}
        />
        <Button
          icon="pi pi-trash"
          className="p-button-rounded p-button-text p-button-plain"
          onClick={() => deleteSelectedNotification(rowData)}
        />
        <Button
          icon="pi pi-eye"
          className="p-button-rounded p-button-text p-button-plain"
          onClick={() => viewSelectedNotification(rowData)}
        />
      </>
    )
  }

  const headerControls = (
    <div className="table-header">
      <Button label={t('globals.new')} className="p-button-success" onClick={openNew} />
    </div>
  )

  const deleteSelectedNotification = (rowData: Notification) => {
    confirmDialog({
      acceptLabel: t('globals.yes'),
      message: t('notifications.confirm_delete_notifications'),
      header: t('globals.confirmation_title'),
      icon: 'pi pi-exclamation-triangle',
      accept: () => deleteNotification(rowData.id),
    })
  }

  const onSubmit = data => {
    confirmDialog({
      acceptLabel: t('globals.yes'),
      message: isEdit
        ? t('notifications.confirm_update_notifications')
        : t('notifications.confirm_create_notifications'),
      header: t('globals.confirmation_title'),
      icon: 'pi pi-exclamation-triangle',
      accept: () => confirmSubmit(data),
    })
  }

  const confirmSubmit = data => {
    const scheduleTime = moment(data.time)
    const scheduleDate = moment(data.date)
      .hour(scheduleTime.hour())
      .minute(scheduleTime.minute())
      .second(0)

    const notificationData = {
      ...data,
      date: scheduleDate.toDate(),
      time: undefined,
    }
    if (isEdit) updateNotification(notificationData)
    else createNewNotification(notificationData)
    reset()
  }

  const clearForm = useCallback(() => {
    reset()
    setNotificationDialog(false)
  }, [reset])

  useEffect(() => {
    if (isCreateSuccess) {
      clearForm()
      toast.current.show({
        severity: 'success',
        summary: t('globals.created'),
        detail: t('notifications.created'),
        life: 10000,
      })
      reset()
      refetch()
    }
  }, [isCreateSuccess, refetch, reset, t, clearForm])

  useEffect(() => {
    if (isUpdateSuccess) {
      clearForm()
      toast.current.show({
        severity: 'success',
        summary: t('globals.updated'),
        detail: t('notifications.updated'),
        life: 5000,
      })
      refetch()
    }
  }, [isUpdateSuccess, refetch, t, clearForm])

  useEffect(() => {
    if (isDeleteSuccess) {
      clearForm()
      toast.current.show({
        severity: 'success',
        summary: t('globals.deleted'),
        detail: t('notifications.deleted'),
        life: 5000,
      })
      refetch()
    }
  }, [isDeleteSuccess, refetch, t, clearForm])

  const closeDialog = () => {
    const valuesNotifications = getValues()
    const originalValues = isEdit ? selectedNotification : defaultValues
    if (
      valuesNotifications.message !== originalValues!.message ||
      valuesNotifications.title !== originalValues!.title ||
      valuesNotifications.language !== originalValues!.language
    ) {
      confirmDialog({
        acceptLabel: t('globals.yes'),
        message: t('globals.confirm_close_form'),
        header: t('globals.confirmation_title'),
        icon: 'pi pi-exclamation-triangle',
        accept: () => setNotificationDialog(false),
      })
    } else {
      setNotificationDialog(false)
    }
  }

  const [overflowDialog, setOverflowDialog] = useState(true)

  const nowDate = useMemo(() => moment().toDate(), [])

  return (
    <div className="card">
      <ConfirmDialog />
      <Toast ref={toast} />
      <Toolbar
        left={<h3>{t('notifications.title')}</h3>}
        right={headerControls}
        style={{ marginBottom: '15px' }}
      />
      <DataTable
        value={formattedNotifications}
        className="p-datatable-striped"
        paginator
        rows={10}
        scrollable
        emptyMessage={t('notifications.emptySet')}
        scrollHeight="flex"
        rowsPerPageOptions={[5, 10, 25]}
        paginatorTemplate="FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink CurrentPageReport RowsPerPageDropdown"
        currentPageReportTemplate={t('notifications.current_page_report')}
        responsiveLayout="scroll"
      >
        <Column field="id" header="#" style={{ minWidth: '3rem' }} />
        <Column field="date" header={t('notifications.date')} style={{ minWidth: '8rem' }} />
        <Column field="time" header={t('notifications.hour')} style={{ minWidth: '5rem' }} />
        <Column
          field="title"
          header={t('notifications.title_create')}
          style={{ minWidth: '10rem' }}
        />
        <Column field="message" header={t('notifications.message')} style={{ minWidth: '15rem' }} />
        <Column
          header={t('globals.actions')}
          body={actionBodyTemplate}
          style={{ minWidth: '8rem' }}
        />
      </DataTable>
      <Dialog
        visible={infoNotificationDialog}
        header={t('globals.info')}
        contentStyle={{
          fontSize: '21px',
          minWidth: '500px',
          paddingTop: '0',
          borderBottomLeftRadius: '6px',
          borderBottomRightRadius: '6px',
          display: 'grid',
          gap: '15px',
        }}
        modal
        className={`p-fluid ${!overflowDialog ? 'overflow-none' : ''}`}
        focusOnShow={false}
        onHide={() => setInfoNotificationDialog(false)}
      >
        <>
          <h3 className="title-notification">
            {t('notifications.title_create')}: {selectedNotification?.title}
          </h3>
          <div className="container-info-notification">
            <span>
              <>
                {t('notifications.date')}: <i className="pi pi-calendar" />
                {selectedNotification?.date}
              </>
            </span>
            <span>
              {t('notifications.hour')}: <i className="pi pi-clock" />
              {selectedNotification?.time}
            </span>
          </div>
          <span>
            {t('notifications.language')}: {t(`languages.${selectedNotification?.language}`)}
          </span>
          <span>
            {t('notifications.message')}: {selectedNotification?.message}
          </span>
        </>
      </Dialog>
      <Dialog
        visible={notificationDialog}
        style={{ width: '700px' }}
        header={
          isEdit
            ? t('notifications.update_notifications_title')
            : t('notifications.create_notifications_title')
        }
        modal
        className="p-fluid"
        onHide={() => closeDialog()}
      >
        <form className="dialog-form" onSubmit={handleSubmit(onSubmit)}>
          <InputForm
            control={control}
            error={errors.title}
            name="title"
            rules={{
              required: t('notifications.required_title'),
              pattern: {
                value: /[a-zA-Z ]/i,
                message: t('notifications.title_invalid'),
              },
            }}
            placeholder={t('notifications.title_create')}
          />
          <div className="container-date">
            <div className="field">
              <span className="p-float-label">
                <Controller
                  name="date"
                  control={control}
                  render={({ field }) => (
                    <Calendar
                      id={field.name}
                      value={field.value}
                      onChange={e => field.onChange(e.value)}
                      dateFormat="dd/mm/yy"
                      mask="99/99/9999"
                      readOnlyInput
                      minDate={nowDate}
                      showIcon
                      locale="es"
                    />
                  )}
                />
                <label htmlFor="date">{t('notifications.date')}</label>
              </span>
            </div>
            <div className="field">
              <span className="p-float-label">
                <Controller
                  name="time"
                  control={control}
                  render={({ field }) => (
                    <Calendar
                      timeOnly
                      hourFormat="12"
                      id={field.name}
                      value={field.value}
                      onChange={e => field.onChange(e.value)}
                      minDate={nowDate}
                      readOnlyInput
                    />
                  )}
                />
                <label htmlFor="time">{t('notifications.hour')}</label>
              </span>
            </div>
            <div className="field">
              <DropDownForm
                control={control}
                name="language"
                options={[
                  { value: 'es', name: t('languages.es') },
                  { value: 'en', name: t('languages.en') },
                ]}
                autoFocus={false}
                placeholder={t('notifications.language')}
                onFocus={() => setOverflowDialog(overflowDialog => !overflowDialog)}
              />
            </div>
          </div>
          <div className="field">
            <span className="p-float-label">
              <Controller
                name="message"
                control={control}
                render={({ field }) => (
                  <InputTextarea
                    rows={5}
                    cols={30}
                    autoResize
                    id={field.name}
                    value={field.value}
                    onChange={e => field.onChange(e.target.value)}
                  />
                )}
              />
              <label htmlFor="message">{t('notifications.message')}</label>
            </span>
          </div>
          <Messages ref={message} />
          <div className="dialog-footer">
            <Button
              label={t('globals.cancel')}
              icon="pi pi-times"
              type="button"
              onClick={() => closeDialog()}
              className="p-button-text"
            />
            <Button
              label={isEdit ? t('globals.save') : t('globals.new')}
              icon="pi pi-check"
              className="p-button-text"
            />
          </div>
        </form>
      </Dialog>
    </div>
  )
}

export { Notifications }
