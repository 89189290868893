import { BrowserRouter as Router, Switch, Route, Redirect } from 'react-router-dom'
import { ThemeProvider } from 'styled-components'
import { QueryClient, QueryClientProvider } from 'react-query'
import { addLocale } from 'primereact/api'

import { UserProvider } from '@contexts'
import { MinigolsLayout, PrivateRoute } from '@components'
import { theme, GlobalStyles } from '@styles'

import { Login } from './Login'
import { Teams } from './Teams'
import { Minigols } from './Minigols'
import { Awards } from './Awards'
import { Notifications } from './Notifications'
import { TransferList } from './TransferList'

const queryClient = new QueryClient()

const App = () => {
  addLocale('es', {
    firstDayOfWeek: 1,
    dayNames: ['domingo', 'lunes', 'martes', 'miércoles', 'jueves', 'viernes', 'sábado'],
    dayNamesShort: ['dom', 'lun', 'mar', 'mié', 'jue', 'vie', 'sáb'],
    dayNamesMin: ['D', 'L', 'M', 'X', 'J', 'V', 'S'],
    monthNames: [
      'enero',
      'febrero',
      'marzo',
      'abril',
      'mayo',
      'junio',
      'julio',
      'agosto',
      'septiembre',
      'octubre',
      'noviembre',
      'diciembre',
    ],
    monthNamesShort: [
      'ene',
      'feb',
      'mar',
      'abr',
      'may',
      'jun',
      'jul',
      'ago',
      'sep',
      'oct',
      'nov',
      'dic',
    ],
    today: 'Hoy',
    clear: 'Limpiar',
  })
  return (
    <ThemeProvider theme={theme}>
      <GlobalStyles />
      <Router>
        <QueryClientProvider client={queryClient}>
          <div>
            <UserProvider>
              <Switch>
                <Route path="/login" component={Login} />
                <PrivateRoute
                  exact
                  path="/equipos"
                  render={() => <MinigolsLayout body={<Teams />} />}
                />
                <PrivateRoute
                  exact
                  path="/minigols"
                  render={() => <MinigolsLayout body={<Minigols />} />}
                />
                <PrivateRoute
                  exact
                  path="/premios"
                  render={() => <MinigolsLayout body={<Awards />} />}
                />
                <PrivateRoute
                  exact
                  path="/notificaciones"
                  render={() => <MinigolsLayout body={<Notifications />} />}
                />
                <PrivateRoute
                  exact
                  path="/transferencias-pendientes"
                  render={() => <MinigolsLayout body={<TransferList />} />}
                />
                <Redirect to="/login" />
              </Switch>
            </UserProvider>
          </div>
        </QueryClientProvider>
      </Router>
    </ThemeProvider>
  )
}

export { App }
