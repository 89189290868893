/* eslint-disable jsx-a11y/label-has-associated-control */
import styled from 'styled-components'
import { Controller, FieldError } from 'react-hook-form'
import { Password } from 'primereact/password'
import { InputText } from 'primereact/inputtext'
import { classNames } from 'primereact/utils'

const StyledInput = styled(InputText)`
  width: 100%;
`
const StyledInputPassword = styled(Password)`
  width: 100%;
`

const HeaderImg = styled.img`
  width: 100%;
`

interface InputFormProps {
  /* eslint-disable  @typescript-eslint/no-explicit-any */
  control: any
  error: FieldError | undefined
  name: string
  rules: {
    required: string
    pattern?: { value: RegExp; message: string }
  }
  placeholder: string
  header?: string
  footer?: string[]
  autoFocus?: boolean
  max?: number
}

const InputForm = (props: InputFormProps) => {
  const getFormErrorMessage = () => {
    return props.error && <small className="p-error">{props.error.message}</small>
  }

  return (
    <div className="field">
      <span className="p-float-label">
        <Controller
          name={props.name}
          control={props.control}
          rules={props.rules}
          render={({ field, fieldState }) => (
            <StyledInput
              id={field.name}
              {...field}
              autoFocus={props.autoFocus ?? true}
              className={classNames({ 'p-invalid': fieldState.invalid })}
              maxLength={props?.max}
            />
          )}
        />
        <label
          htmlFor={props.name}
          className={classNames({
            'p-error': !!props.error,
          })}
        >
          {props.placeholder}
        </label>
      </span>
      {getFormErrorMessage()}
    </div>
  )
}

const InputFormPassword = (props: InputFormProps) => {
  const getFormErrorMessage = () => {
    return props.error && <small className="p-error">{props.error.message}</small>
  }

  return (
    <div className="field">
      <span className="p-float-label">
        <Controller
          name={props.name}
          control={props.control}
          rules={props.rules}
          render={({ field, fieldState }) => (
            <StyledInputPassword
              id={field.name}
              {...field}
              toggleMask
              feedback={false}
              className={classNames({ 'p-invalid': fieldState.invalid })}
            />
          )}
        />
        <label
          htmlFor={props.name}
          className={classNames({
            'p-error': props.error,
          })}
        >
          {props.placeholder}
        </label>
      </span>
      {getFormErrorMessage()}
    </div>
  )
}

export { InputForm, InputFormPassword, HeaderImg }
