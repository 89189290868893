import { v4 as uuidv4 } from 'uuid'

import { AwardsType, AwardType, Filters } from '@types'

import { api } from './axios'

interface AwardsPayloadGet {
  data: AwardsType[]
  total: number
}

interface AwardsPayload {
  awardsId?: number
  awards: AwardType
  picture: File
  name?: string
}

interface DeleteAwardsPayload {
  awardsId: number
}

interface TransferAward {
  mNftToSendId: number
  transferedDate: string
  notes: string
}

const getAll = async ({ page, limit }: Filters) => {
  const params = {
    page,
    limit,
  }
  const response = await api.get<AwardsPayloadGet>('/mnfts', { params })
  return response.data
}

const newAwards = async (payload: AwardsPayload) => {
  const awardsPayload = new FormData()
  if (payload.picture) awardsPayload.append('picture', payload.picture)
  if (payload.awards.tokenId) {
    awardsPayload.append(
      'mNft',
      JSON.stringify({ tokenId: payload.awards.tokenId ? payload.awards.tokenId : uuidv4() }),
    )
  }
  const response = await api.post('/mnfts', awardsPayload)
  return response.data
}

const updateAwards = async (payload: AwardsPayload) => {
  const awardsPayload = new FormData()
  if (payload.picture) awardsPayload.append('picture', payload.picture)
  awardsPayload.append(
    'mNft',
    JSON.stringify({
      tokenId: payload.awards.tokenId ? payload.awards.tokenId : null,
      name: payload.name,
    }),
  )
  const response = await api.put(`/mnfts/${payload.awardsId}`, awardsPayload)
  return response.data
}

const deleteAwards = async (payload: DeleteAwardsPayload) => {
  const response = await api.delete(`/mnfts/${payload.awardsId}`)
  return response.data
}

const transferAwards = async (payload: TransferAward) => {
  const response = await api.put('/mnft-to-send/', payload)
  return response.data
}

export const AwardsApi = {
  getAll,
  newAwards,
  deleteAwards,
  updateAwards,
  transferAwards,
}
