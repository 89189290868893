import { css } from 'styled-components'

const fonts = css`
  //Nunito
  @font-face {
    font-family: 'Nunito-Regular';
    src: url('/fonts/Nunito/Nunito-Regular.ttf');
  } //400
`

export { fonts }
