/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable jsx-a11y/label-has-associated-control */
import { useState, useRef, useEffect, useCallback } from 'react'
import { Dialog } from 'primereact/dialog'
import { useForm } from 'react-hook-form'
import { DataTable } from 'primereact/datatable'
import { Column } from 'primereact/column'
import { Toolbar } from 'primereact/toolbar'
import { Button } from 'primereact/button'
import { FileUpload } from 'primereact/fileupload'
import { ConfirmDialog, confirmDialog } from 'primereact/confirmdialog'
import { Toast } from 'primereact/toast'
import { Messages } from 'primereact/messages'
import { Image } from 'primereact/image'
import { useTranslation } from 'react-i18next'
import styled from 'styled-components'

import { InputForm } from '@components'
import { ErrorResponse } from '@types'
import { useTeams, useApiErrorService } from '@hooks'
import { teamEmpty } from '@images'
import { StringUtils } from '@utils'

import { useDeleteTeam } from './hooks/useDeleteTeam'
import { useNewTeam } from './hooks/useNewTeam'
import { useUpdateTeam } from './hooks/useUpdateTeam'
import { usePublishTeam } from './hooks/usePublishTeam'

const ImgLogo = styled.img`
  width: 60px;
  height: 60px;
  object-fit: scale-down;
`

interface Team {
  id?: number
  name: string
  image?: string
  publish: boolean
  createdAt?: string
}

const Teams = () => {
  const fileUploadRef = useRef<any>(null)
  const toast = useRef<any>(null)
  const message = useRef<any>(null)
  const [teamDialog, setTeamDialog] = useState(false)
  const [imgTeam, setImgTeam] = useState<File | undefined>()
  const [imgTeamOld, setImgTeamOld] = useState<string | undefined>()
  const [isEdit, setIsEdit] = useState(false)
  const [publish, setPublish] = useState(false)
  const filtro = {
    page: 1,
    limit: 50,
  }
  const { mapErrorToMessage } = useApiErrorService()
  const { deleteTeam, isDeleteSuccess } = useDeleteTeam()
  const { refetch, teams } = useTeams(filtro)

  const { t } = useTranslation()
  const defaultValues = {
    id: '',
    name: '',
  }

  const {
    control,
    setValue,
    formState: { errors },
    handleSubmit,
    getValues,
    reset,
  } = useForm({ defaultValues })

  const openNew = () => {
    clearForm()
    setIsEdit(false)
    setTeamDialog(true)
  }

  const editSelectedTeam = team => {
    setImgTeamOld(team.image)
    setValue('name', team.name)
    setValue('id', team.id)
    setIsEdit(true)
    setTeamDialog(true)
  }

  const handelPublishTeam = (teamId: number) => {
    publishTeam({ teamId })
  }

  const { publishTeam, isPublishSuccess } = usePublishTeam({
    publish,
    onError: (error: ErrorResponse) => {
      toast.current.show({
        severity: 'error',
        summary: mapErrorToMessage(error),
        detail: mapErrorToMessage(error, '_DETAIL'),
        life: 5000,
      })
    },
  })

  const actionBodyTemplate = rowData => {
    return (
      <>
        <Button
          icon="pi pi-pencil"
          className="p-button-rounded p-button-text p-button-plain"
          onClick={() => editSelectedTeam(rowData)}
        />
        <Button
          icon="pi pi-trash"
          className="p-button-rounded p-button-text p-button-plain"
          onClick={() => deleteSelectedTeam(rowData)}
          disabled={rowData.publish ?? true}
        />
        <Button
          icon="pi pi-cloud-upload"
          className="p-button-rounded p-button-text p-button-plain"
          onClick={() => publishSelectedTeam(rowData)}
        />
      </>
    )
  }

  const headerControls = (
    <div className="table-header">
      <Button label={t('globals.new')} className="p-button-success" onClick={openNew} />
    </div>
  )

  const deleteSelectedTeam = rowData => {
    confirmDialog({
      acceptLabel: t('globals.yes'),
      message: t('teams.confirm_delete_team'),
      header: t('globals.confirmation_title'),
      icon: 'pi pi-exclamation-triangle',
      accept: () => deleteTeam({ teamId: rowData.id }),
    })
  }

  const publishSelectedTeam = rowData => {
    const { id, publish } = rowData
    setPublish(publish)
    confirmDialog({
      acceptLabel: t('globals.yes'),
      message: publish ? t('teams.confirm_unpublish_team') : t('teams.confirm_publish_team'),
      header: t('globals.confirmation_title'),
      icon: 'pi pi-exclamation-triangle',
      accept: () => handelPublishTeam(id),
    })
  }

  const imageBodyTemplate = rowData => {
    return (
      <ImgLogo
        loading="lazy"
        src={rowData.image ? rowData.image : teamEmpty}
        alt={`Logo ${rowData.name}`}
      />
    )
  }

  const statusBodyTemplate = rowData => {
    return (
      <span className={`status-badge status-${rowData.publish}`}>
        {rowData.publish === true ? t('globals.published') : t('teams.not_published')}
      </span>
    )
  }

  const onSubmit = data => {
    confirmDialog({
      acceptLabel: t('globals.yes'),
      message: isEdit ? t('teams.confirm_update_team') : t('teams.confirm_create_team'),
      header: t('globals.confirmation_title'),
      icon: 'pi pi-exclamation-triangle',
      accept: () => confirmSubmit(data),
    })
  }

  const closeDialog = useCallback(() => {
    const valuesTeam = getValues()
    if (valuesTeam.name) {
      confirmDialog({
        acceptLabel: t('globals.yes'),
        message: t('globals.confirm_close_form'),
        header: t('globals.confirmation_title'),
        icon: 'pi pi-exclamation-triangle',
        accept: () => setTeamDialog(false),
      })
    } else {
      setTeamDialog(false)
    }
  }, [getValues, t])

  const confirmSubmit = (data: Team) => {
    message.current.clear()
    return isEdit
      ? updateTeam({
          teamId: data.id,
          name: StringUtils.initialCapitalLetters(data.name),
          picture: imgTeam as File,
        })
      : createNewTeam({
          name: StringUtils.initialCapitalLetters(data.name),
          picture: imgTeam as File,
        })
  }

  const messageError = (error: ErrorResponse) => {
    setImgTeam(undefined)
    message.current.show({
      severity: 'error',
      detail: mapErrorToMessage(error),
      life: 10000,
    })
  }

  const { createNewTeam, isCreateLoading, isCreateSuccess } = useNewTeam({
    onError: (error: ErrorResponse) => {
      messageError(error)
    },
  })

  const { updateTeam, isUpdateLoading, isUpdateSuccess } = useUpdateTeam({
    onError: (error: ErrorResponse) => {
      messageError(error)
    },
  })

  const clearForm = useCallback(() => {
    reset()
    if (fileUploadRef.current) fileUploadRef.current.clear()
  }, [reset, fileUploadRef])

  // refactor this
  useEffect(() => {
    if (isPublishSuccess) {
      setTeamDialog(false)
      refetch()
      toast.current.show({
        severity: 'success',
        summary: !publish ? t('globals.published') : t('globals.unpublished'),
        detail: !publish ? t('teams.published') : t('teams.unpublished'),
        life: 5000,
      })
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isPublishSuccess, refetch, t])

  useEffect(() => {
    if (isCreateSuccess) {
      setTeamDialog(false)
      setImgTeam(undefined)
      toast.current.show({
        severity: 'success',
        summary: t('globals.created'),
        detail: t('teams.created'),
        life: 5000,
      })
      refetch()
    }
  }, [isCreateSuccess, refetch, t])

  useEffect(() => {
    if (isUpdateSuccess) {
      setTeamDialog(false)
      setImgTeam(undefined)
      toast.current.show({
        severity: 'success',
        summary: t('globals.updated'),
        detail: t('teams.updated'),
        life: 5000,
      })
      refetch()
    }
  }, [isUpdateSuccess, refetch, t])

  useEffect(() => {
    if (isDeleteSuccess) {
      setTeamDialog(false)
      toast.current.show({
        severity: 'success',
        summary: t('globals.deleted'),
        detail: t('teams.deleted'),
        life: 5000,
      })
      refetch()
    }
  }, [isDeleteSuccess, refetch, t])

  return (
    <div className="card">
      <ConfirmDialog />
      <Toast ref={toast} />
      <Toolbar
        left={<h3>{t('teams.title')}</h3>}
        right={headerControls}
        style={{ marginBottom: '15px' }}
      />
      <DataTable
        value={teams}
        className="p-datatable-striped"
        rows={10}
        scrollable
        scrollHeight="flex"
        responsiveLayout="scroll"
        lazy
        loading={!teams}
      >
        <Column field="id" header="#" style={{ minWidth: '3rem' }} />
        <Column field="name" header={t('teams.name')} style={{ minWidth: '10rem' }} />
        <Column header={t('teams.image')} style={{ minWidth: '7rem' }} body={imageBodyTemplate} />
        <Column
          field="publish"
          header={t('teams.status')}
          style={{ minWidth: '9rem' }}
          body={statusBodyTemplate}
        />
        <Column
          header={t('globals.actions')}
          style={{ minWidth: '10rem' }}
          body={actionBodyTemplate}
        />
      </DataTable>
      <Dialog
        visible={teamDialog}
        style={{ width: '700px' }}
        header={t('teams.create_team_title')}
        modal
        className="p-fluid"
        onHide={() => closeDialog()}
      >
        <form className="dialog-form" onSubmit={handleSubmit(onSubmit)}>
          <InputForm
            control={control}
            error={errors.name}
            name="name"
            rules={{
              required: t('teams.name_required'),
              pattern: {
                value:
                  /^[a-zA-ZÀ-ÿ'\u00f1\u00d1]+(\s*[a-zA-ZÀ-ÿ'\u00f1\u00d1]*)*[a-zA-ZÀ-ÿ'\u00f1\u00d1]+$/,
                message: t('teams.name_invalid'),
              },
            }}
            placeholder={t('teams.name')}
          />
          <div className="container-file-img">
            <FileUpload
              ref={fileUploadRef}
              name="image"
              accept="image/png, image/jpeg"
              auto
              emptyTemplate={<p>{t('globals.image_drop')}</p>}
              customUpload
              chooseOptions={{
                label: t('globals.image_choose'),
                className: 'p-button-without-icon',
              }}
              onRemove={() => setImgTeam(undefined)}
              uploadHandler={event => setImgTeam(event.files[0])}
            />
            {isEdit && imgTeamOld && <Image src={imgTeamOld} alt={getValues('name')} preview />}
          </div>
          <Messages ref={message} />
          <div className="dialog-footer">
            <Button
              label={t('globals.cancel')}
              icon="pi pi-times"
              type="button"
              onClick={() => closeDialog()}
              className="p-button-text"
            />
            <Button
              loading={isUpdateLoading || isCreateLoading}
              label={t('globals.save')}
              icon="pi pi-check"
              className="p-button-text"
            />
          </div>
        </form>
      </Dialog>
    </div>
  )
}

export { Teams }
